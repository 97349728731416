import React, { ReactElement, useContext, useState } from "react";
import StatusView, { StatusType, StatusItem } from "./StatusView";
import statusDescriptions from "../resources/status_descriptions.json";
import "../styles/MainContentView.scss";
import { ThemeContext } from "../theme";
import { capitalizeWords } from "../util/util";
import { Status } from "../models/Model";
import FeedbackView from "./FeedbackView";

interface Props {
  customerName: string;
  dealerName: string;
  vehicle: {
    imageUrl?: string;
    year: string;
    model: string;
    trim: string;
    make: string;
  };
  pickupTime: string;
  status: Status;
  bearerToken: string;
}

export default function MainContentView(props: Props): ReactElement {
  const context = useContext(ThemeContext);
  const [feedbackClosedOnMobile, setFeedbackClosedOnMobile] = useState(false);

  const indexOfStatus = (status: string): number => {
    return statusDescriptions.findIndex((s) => s.key === status);
  };

  const pickupTimestamp = Date.parse(props.pickupTime)
  const hasPickUpTime = (pickupTimestamp && !isNaN(pickupTimestamp))
  const formattedPickupDate = new Date(pickupTimestamp).toLocaleString();

  const inspectionStageStatuses = (): StatusItem[] => {
    const currentStatusIndex = indexOfStatus(props.status);
    return statusDescriptions.map((status) => {
      let type: StatusType;
      const index = indexOfStatus(status.key);
      if (index < currentStatusIndex) {
        type = "complete";
      } else if (index === currentStatusIndex) {
        type = "in-progress";
      } else {
        type = "in-queue";
      }
      return {
        ...status,
        title:
          context.make === "Nissan"
            ? status.title
            : capitalizeWords(status.title),
        type: type,
      };
    });
  };

  const feedbackData = {
    bearerToken: props.bearerToken,
    name: props.customerName,
    dealer: props.dealerName,
    brand: props.vehicle.make,
  };
  return (
    <div className="MainContentView">
      <div className="vehicleContainer">
        {props.vehicle.imageUrl && (
          <img
            className="vehicleImage"
            src={props.vehicle.imageUrl}
            alt="Vehicle"
          />
        )}
        <div className="vehicleTextContainer">
          <div className="descriptionText">
            {`${props.customerName}'s ${props.vehicle.year} ${props.vehicle.model} ${props.vehicle.trim}`}
          </div>
          <div className="descriptionDivider" />
          {hasPickUpTime? <div>
            <div className="pickupTimeTitle">ESTIMATED PICKUP TIME:</div>
            <div className="pickupTime">{formattedPickupDate}</div>
          </div> : undefined}
        </div>
      </div>
      <div className="divider" />
      <StatusView
        statusItems={inspectionStageStatuses()}
        dealerName={props.dealerName}
      />

      {props.status === "READY_FOR_PICKUP" && !feedbackClosedOnMobile && (
        <FeedbackView
          onClosed={() => setFeedbackClosedOnMobile(true)}
          {...feedbackData}
        />
      )}
    </div>
  );
}
