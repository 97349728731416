import React from "react";
import "../styles/RecallView.scss";
import recallIcon from "../resources/recallIcon.svg";
import ChevronImage, { Transform } from "./ChevronImage";

interface Props {
  recalls: any[];
}

const RecallView = (props: Props) => {
  return (
    <div className="RecallView">
      <div className="recallCountContainer">
        <img style={{ marginRight: 8 }} src={recallIcon} alt="" />
        <div>
          {props.recalls.length === 1
            ? "You have a vehicle recall"
            : `You have ${props.recalls.length} vehicle recalls`}
        </div>
      </div>
      <a
        className="learnMoreButton"
        href="https://www.nissanusa.com/recalls-vin/#/#/Home"
      >
        <div>LEARN MORE</div>
        <ChevronImage
          transform={Transform.Right}
          style={{ marginLeft: 6, filter: "brightness(0%)" }}
        />
      </a>
    </div>
  );
};

export default RecallView;
