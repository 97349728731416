import React from "react";
import { Make } from "./models/Model";

export interface Theme {
  make: Make;
  mobileLayoutActive: boolean; // defined so we can modify certain UI elements when in a mobile layout (chevrons for collapsible sections on mobile)
  carGuid: string;
}
export const ThemeContext = React.createContext<Theme>({
  make: "Nissan",
  mobileLayoutActive: false,
  carGuid: "",
});
