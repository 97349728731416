import React, { ReactElement, CSSProperties } from "react";
import { ReactComponent as ChevronIcon } from "../resources/chevron.svg";
import "../styles/ChevronImage.scss";

export enum Transform {
  Up = -90,
  Right = 0,
  Down = 90,
}

export default function ChevronImage(props: {
  transform: Transform;
  style?: CSSProperties;
}): ReactElement {
  return (
    <ChevronIcon
      className="ChevronImage"
      style={{
        transform: `rotate(${props.transform}deg`,
        ...(props.style ?? {}),
      }}
    />
  );
}
