import React, { ReactElement, useContext } from "react";
import ServiceAdvisorView from "./ServiceAdvisorView";
import DealerView from "./DealerView";
import DealerHoursView from "./DealerHoursView";
import { DealerHoursForDay } from "../models/Model";
import { ThemeContext } from "../theme";
import "../styles/SidebarView.scss";

interface Props {
  serviceAdvisor: {
    name: string;
    number: string;
    chatLink: string;
  };
  dealer: {
    name: string;
    address: string;
    hours: DealerHoursForDay[];
  };
}

export default function SidebarView(props: Props): ReactElement {
  const context = useContext(ThemeContext);

  return (
    <div className="SidebarView">
      <div className="prompt">
        HAVE QUESTIONS OR CONCERNS REGARDING YOUR SERVICE TODAY?
      </div>
      <div className="infoContainer">
        <div className="top">
          <ServiceAdvisorView {...props.serviceAdvisor} profileImageUrl="" />
          <DealerView {...props.dealer} />
        </div>
        <div className="divider" />
        <DealerHoursView
          hours={props.dealer.hours}
          mobileLayoutActive={context.mobileLayoutActive}
        />
      </div>
    </div>
  );
}
