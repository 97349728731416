import React, { ReactElement, useContext, useState } from "react";
import "../styles/FeedbackView.scss";
import { ReactComponent as FeedbackIcon } from "../resources/feedbackIcon.svg";
import { ReactComponent as RatingPositiveIcon } from "../resources/ratingPositive.svg";
import { ReactComponent as RatingNeutralIcon } from "../resources/ratingNeutral.svg";
import { ReactComponent as RatingNegativeIcon } from "../resources/ratingNegative.svg";
import { ReactComponent as CheckmarkLargeIcon } from "../resources/checkmarkLarge.svg";
import { SSTButton } from "./SSTButton";
import { SidebarButton } from "./SidebarButton";
import { ThemeContext } from "../theme";
import axios from "axios";
import config from "../config";

interface Props {
  onClosed: () => void;
  brand: string;
  name: string;
  dealer: string;
  bearerToken: string;
}

export default function FeedbackView(props: Props): ReactElement {
  const context = useContext(ThemeContext);
  const feedbackSubmittedLocalStorageKey = `feedback_${context.carGuid}`;

  const [currentRating, setCurrentRating] = useState<string>("positive");
  const [feedback, setFeedback] = useState<string>("");
  const [sendingFeedback, setSendingFeedback] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(
    window.localStorage.getItem(feedbackSubmittedLocalStorageKey) !== null
  );

  const getRatingNum = (rating: string): number | undefined => {
    switch (rating) {
      case "positive":
        return 1;
      case "neutral":
        return 0;
      case "negative":
        return -1;
    }
  };
  const submitFeedback = (rating: string, feedback: string) => {
    setSendingFeedback(true);

    const feedbackData = {
      appVersion: "1.0",
      appSource: "SST",
      brand: props.brand,
      name: props.name,
      dealer: props.dealer,
      comment: feedback,
      response: getRatingNum(rating),
    };
    const headers = { Authorization: `Bearer ${props.bearerToken}` };
    axios.post(config.feedbackUrl, feedbackData, { headers }).then(() => {
      setSendingFeedback(false);

      window.localStorage.setItem(feedbackSubmittedLocalStorageKey, "true");
      setFeedbackSubmitted(true);
    });
  };

  return (
    <div className={`FeedbackView ${sendingFeedback ? "sendingFeedback" : ""}`}>
      <div className="feedbackContainer">
        <div className="feedbackContent">
          <div className="closeButtonTop" onClick={props.onClosed}>
            X
          </div>
          {!feedbackSubmitted && (
            <FeedbackEntryView
              currentRating={currentRating}
              feedback={feedback}
              sendingFeedback={sendingFeedback}
              onClickRating={setCurrentRating}
              onFeedbackChange={setFeedback}
              onSubmitFeedback={submitFeedback}
            />
          )}
          {feedbackSubmitted && (
            <FeedbackSubmittedView onClosed={props.onClosed} />
          )}
        </div>
      </div>
    </div>
  );
}

const FeedbackSubmittedView = (props: { onClosed: () => void }) => {
  return (
    <div className="FeedbackSubmittedView">
      <CheckmarkLargeIcon className="feedbackCheckmark" />
      <div className="thanks">Thanks for helping us!</div>
      <div>
        We are always listening to your feedback to make our products better.
      </div>
      <div className="closeButtonBottom">
        <SidebarButton
          title="CLOSE"
          onClick={() => {
            props.onClosed();
            return true;
          }}
        />
      </div>
    </div>
  );
};

const FeedbackEntryView = (props: {
  currentRating: string;
  feedback: string;
  sendingFeedback: boolean;
  onClickRating: (rating: string) => void;
  onFeedbackChange: (feedback: string) => void;
  onSubmitFeedback: (rating: string, feedback: string) => void;
}) => {
  const iconClassNameForRating = (rating: string): string => {
    return `ratingIcon ${rating} ${
      props.currentRating === rating ? "selected" : ""
    }`;
  };

  const feedbackPlaceholderText = (): string => {
    switch (props.currentRating) {
      case "positive":
        return "How was your experience? (optional)";
      case "neutral":
        return "What would you improve? (optional)";
      case "negative":
        return "What went wrong? (optional)";
    }
    return "";
  };

  return (
    <div className="FeedbackEntryView">
      <div className="improveService">
        <FeedbackIcon className="improveServiceIcon" />
        Help us to improve our service
      </div>

      <div style={{ marginBottom: 20 }}>
        How did you find using the <b>SERVICE STATUS TRACKER?</b>
      </div>

      <div className="ratingContainer">
        <RatingPositiveIcon
          className={iconClassNameForRating("positive")}
          onClick={() => props.onClickRating("positive")}
        />
        <RatingNeutralIcon
          className={iconClassNameForRating("neutral")}
          onClick={() => props.onClickRating("neutral")}
        />
        <RatingNegativeIcon
          className={iconClassNameForRating("negative")}
          onClick={() => props.onClickRating("negative")}
        />
      </div>
      <form className="feedbackForm">
        <textarea
          value={props.feedback}
          placeholder={feedbackPlaceholderText()}
          onChange={(event) => props.onFeedbackChange(event.target.value)}
        />
      </form>
      <SSTButton
        className="submitButton"
        title={`${props.sendingFeedback ? "SUBMITTING..." : "SUBMIT"}`}
        chevronVisible={!props.sendingFeedback}
        onClick={() =>
          props.onSubmitFeedback(props.currentRating, props.feedback)
        }
      />
    </div>
  );
};
