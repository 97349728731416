import React, { ReactElement, useContext } from "react";
import ChevronImage, { Transform } from "./ChevronImage";
import "../styles/SSTButton.scss";
import { ThemeContext } from "../theme";

export const SSTButton = (props: {
  title: string;
  chevronVisible: boolean;
  iconSrc?: string;
  className?: string;
  onClick: () => void;
}): ReactElement => {
  const context = useContext(ThemeContext);

  return (
    <div
      className={`SSTButton ${props.className || ""}`}
      onClick={props.onClick}
    >
      {props.iconSrc && <img className="icon" src={props.iconSrc} alt="" />}
      <div>{props.title}</div>
      {context.make === "Nissan" && props.chevronVisible && (
        <ChevronImage transform={Transform.Right} style={{ marginLeft: 12 }} />
      )}
    </div>
  );
};
