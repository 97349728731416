import React, { ReactElement, useContext } from "react";
import "../styles/FooterView.scss";
import { ThemeContext } from "../theme";
import { useScript } from "../util/useScript";

export default function FooterView(): ReactElement {
  const context = useContext(ThemeContext);
  const isNissan = context.make === "Nissan";

  const scriptUrl = isNissan
    ? "https://www.nissanusa.com/content/dam/Nissan/us/assets/includes/menu/js/header-include.js"
    : "https://www.infinitiusa.com/content/dam/Infiniti/US/assets/includes/menu/js/footer-include.js";
  useScript(scriptUrl, (event) => {
    // Hide this div element from the infiniti footer because it puts
    // a strange top bar on the nav header (that for some reason isn't part of the infinitiusa.com site).
    const divsToHide = document.getElementsByClassName("c_054-2") as HTMLCollectionOf<HTMLElement>;
    if (divsToHide.length > 0) {
      divsToHide[0].style.display = "none";
    }
  });

  return (
    <div className="FooterView">
      {isNissan && (
        <div>
          <link
            rel="stylesheet"
            media="print, screen"
            href="https://www.nissanusa.com/content/dam/Nissan/us/assets/includes/menu/css/small.min.css"
          />
          <link
            rel="stylesheet"
            media="print, screen and (min-width: 36.3125em)"
            href="https://www.nissanusa.com/content/dam/Nissan/us/assets/includes/menu/css/medium.min.css"
          />
          <link
            rel="stylesheet"
            media="print, screen and (min-width: 60em)"
            href="https://www.nissanusa.com/content/dam/Nissan/us/assets/includes/menu/css/large.min.css"
          />
          <link
            rel="stylesheet"
            media="print"
            href="https://www.nissanusa.com/content/dam/Nissan/us/assets/includes/menu/css/print.min.css"
          />

          <div id="nissan_global_footer"></div>
        </div>
      )}
      {!isNissan && (
        <div>
          <link
            rel="stylesheet"
            media="print, screen"
            href="https://www.infinitiusa.com/etc/designs/infiniti_next/20.10.5.NISSAN-5/common-assets/css/small.min.css"
          />
          <link
            rel="stylesheet"
            media="print, screen and (min-width: 36.3125em)"
            href="https://www.infinitiusa.com/etc/designs/infiniti_next/20.10.5.NISSAN-5/common-assets/css/medium.min.css"
          />
          <link
            rel="stylesheet"
            media="print, screen and (min-width: 60em)"
            href="https://www.infinitiusa.com/etc/designs/infiniti_next/20.10.5.NISSAN-5/common-assets/css/large.min.css"
          />
          <link
            rel="stylesheet"
            media="print"
            href="https://www.infinitiusa.com/etc/designs/infiniti_next/20.10.5.NISSAN-5/common-assets/css/print.min.css"
          />

          <div id="infiniti_global_footer"></div>
        </div>
      )}
    </div>
  );
}
