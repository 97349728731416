import React, { ReactElement, useContext } from "react";
import ChevronImage, { Transform } from "./ChevronImage";
import "../styles/SidebarButton.scss";
import { ThemeContext } from "../theme";

// SidebarButton normally acts as an anchor tag, but its styling and functionality
// can also be reused with a click listener in the event that an href is unneeded.
export const SidebarButton = (props: {
  title: string;
  iconSrc?: string;
  href?: string;
  className?: string;
  onClick?: () => boolean;
}): ReactElement => {
  const context = useContext(ThemeContext);

  return (
    <a
      className={`SidebarButton ${props.className || ""}`}
      href={props.href}
      onClick={props.onClick}
    >
      {props.iconSrc && <img className="icon" src={props.iconSrc} alt="" />}
      <div>{props.title}</div>
      {context.make === "Nissan" && (
        <ChevronImage transform={Transform.Right} style={{ marginLeft: 12 }} />
      )}
    </a>
  );
};
