import { Make } from "../models/Model";

// https://stackoverflow.com/a/7592235/461277
export const capitalizeWords = (string: string): string => {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

/**
 * Formats the vehicle make we get from the API into a format we can use for displaying the make name to the user.
 * @param make - The Make as provided by the API
 * @param includeQualifier - Whether the make should be prefixed by "a" or "an" in case the make is the object of a sentence.
 */
export const formattedMakeName = (
  make: Make,
  includeQualifier: boolean
): string => {
  switch (make) {
    case "Infiniti": {
      return `${includeQualifier ? "an " : ""}${make.toUpperCase()}`;
    }
    case "Nissan": {
      return `${includeQualifier ? "a " : ""}${capitalizeWords(make)}`;
    }
  }
};
