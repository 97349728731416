import { useState, useEffect } from "react";
import globals from "./globals";

interface LayoutInfo {
  width: number;
  height: number;
  usingMobileLayout: boolean;
}

/**
 *  Custom hook for observing the windows size. We want to know when the app transitions
 *  to / from a mobile-style layout in some cases. This hook makes it easy to do so.
 */
export default function useLayoutInfo(): LayoutInfo {
  const [layoutInfo, setLayoutInfo] = useState(getLayoutInfo());

  useEffect(() => {
    function handleResize() {
      setLayoutInfo(getLayoutInfo());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return layoutInfo;
}

// Helpers
const getLayoutInfo = (): LayoutInfo => {
  const dimensions = getWindowDimensions();
  return {
    ...dimensions,
    usingMobileLayout: getMobileLayoutActive(dimensions.width)
  };
};

const getWindowDimensions = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

const getMobileLayoutActive = (width: number): boolean => {
  return width <= globals.mobileMaxWidth;
};
