import React, { ReactElement, useEffect, useState, useContext } from "react";
import { ThemeContext } from "../theme";
import "../styles/StatusView.scss";
import infoIcon from "../resources/info.svg";
import checkmarkIcon from "../resources/checkmark.svg";
import ChevronImage, { Transform } from "./ChevronImage";
import { formattedMakeName } from "../util/util";

export type StatusType = "complete" | "in-progress" | "in-queue";
export type StatusItem = {
  key: string;
  title: string;
  description: string;
  type: StatusType;
};

interface Props {
  statusItems: StatusItem[];
  dealerName: string;
}

export default function StatusView(props: Props): ReactElement {
  const context = useContext(ThemeContext);

  // Tracks the status item the user has selected.
  const inProgressIndex = props.statusItems.findIndex(
    (statusItem) => statusItem.type === "in-progress"
  );
  const [selectedItemIndex, setSelectedItemIndex] = useState(inProgressIndex);

  useEffect(() => {
    setSelectedItemIndex(inProgressIndex);
  }, [inProgressIndex]);

  // State variable we use for tracking when the user has opted to show/hide
  // the statusDescription field. Note that this variable is only applicable
  // for mobile.
  const [statusDescriptionExpanded, setStatusDescriptionExpanded] = useState(
    false
  );

  const currentStatusItem = props.statusItems[selectedItemIndex];
  const shouldDisplayStatusDescription =
    statusDescriptionExpanded || !context.mobileLayoutActive;

  return (
    <div className="StatusView">
      <div className="statusTextContainer">
        <div className="statusText">
          {currentStatusItem.type.toUpperCase().replace("-", " ")}
        </div>
        <div className="statusStep">
          {`STEP ${selectedItemIndex + 1} OF ${props.statusItems.length}`}
        </div>
      </div>

      <div className="itemContainer">
        {props.statusItems.map((item, index) => {
          return (
            <StatusItemView
              key={item.key}
              item={item}
              step={index + 1}
              selected={index === selectedItemIndex}
              isLast={index === props.statusItems.length - 1}
              onSelected={() => setSelectedItemIndex(index)}
            />
          );
        })}
      </div>
      <div className="mobileOnlyCurrentStatusText">
        {currentStatusItem.title}
      </div>
      <div className="statusDescription">
        <div
          className="titleContainer"
          onClick={() =>
            context.mobileLayoutActive
              ? setStatusDescriptionExpanded(!statusDescriptionExpanded)
              : undefined
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img className="infoIcon" src={infoIcon} alt="" />
            <div className="header">For your information</div>
          </div>
          {context.mobileLayoutActive && (
            <ChevronImage
              transform={
                shouldDisplayStatusDescription ? Transform.Up : Transform.Down
              }
            />
          )}
        </div>
        {/* Not dangerous because the description comes from the local json file we control */}
        {/* There are a few template strings in our payload. This is a cheap way of doing things,
            but since the text fields are small there shouldn't be a noticeable performance hit.
        */}
        {shouldDisplayStatusDescription && (
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: currentStatusItem.description
                .replace("{:dealerName}", props.dealerName)
                .replace(
                  "{:makeNameWithQualifier}",
                  formattedMakeName(context.make, true)
                )
                .replace("{:makeName}", formattedMakeName(context.make, false))
                .replace(
                  "{:advisor_position}",
                  context.make === "Nissan"
                    ? "Service Advisor"
                    : "Service Consultant"
                ),
            }}
          />
        )}
      </div>
    </div>
  );
}

interface StatusItemProps {
  item: StatusItem;
  step: number;
  selected: boolean;
  isLast: boolean;
  onSelected(): void;
}

function StatusItemView({
  item,
  step,
  selected,
  isLast,
  onSelected,
}: StatusItemProps): ReactElement {
  return (
    <div className="StatusItemView">
      <div className={`progressLine ${item.type} ${isLast && "last"}`} />
      <div
        className={`oval ${item.type} ${selected && "selected"}`}
        onClick={onSelected}
      >
        {item.type === "complete" ? (
          <img className="checkmarkIcon" src={checkmarkIcon} alt="" />
        ) : (
          <div>{step}</div>
        )}
      </div>
      <div className={`title ${item.type}`}>{item.title}</div>
    </div>
  );
}
