export type DealerHoursForDay = {
  day: string;
  range: string;
};

export type Status =
  | "CHECK_IN"
  | "INSPECTION"
  | "INSPECTION_REVIEW"
  | "PERFORMING_SERVICE"
  | "QUALITY_CHECK"
  | "READY_FOR_PICKUP";

export type Make = "Nissan" | "Infiniti";

export type FeedbackRating = "positive" | "neutral" | "negative";

export default interface Model {
  vin?: string;
  custInfo: {
    firstName: string;
    lastName: string;
  };
  dealerInfo: {
    dealerAddressLine1: string;
    dealerCityName: string;
    dealerCountry: string;
    dealerLatitude: string;
    dealerLongitude: string;
    dealerName: string;
    dealerServiceHours: {
      day: string;
      range: string;
    }[];
    dealerServicePhone: string;
    dealerStateCode: string;
    dealerZip: string;
  };
  recallInfo: {
    defectSummary: string;
    nhtsarecallId: string;
    nnarecallId: string;
    recallEffectiveDate: string;
    recallSecondaryDescription: string;
    recallTypeCode: string;
  }[];
  serviceStatusInfo: {
    advisorPhone: string;
    estimatedPickUp: string;
    chatUrl: string;
    serviceAdvisorName: string;
    serviceStatus: Status;
  };
  vehicleInfo: {
    exteriorColor: string;
    make: Make;
    model: string;
    trim: string;
    year: string;
    imageUrl?: string;
  };
}

const convertDealerServiceHours = (hours: string): DealerHoursForDay[] => {
  const separatedByEntry = hours.split(";");
  const value = separatedByEntry.map((cur: string) => {
    const separatedRanges = cur.split(/:(.+)/);
    return {
      day: separatedRanges[0],
      range: separatedRanges[1] ? separatedRanges[1] : "",
    };
  });
  return value;
};

export const createDefaultModel = (vin: string, pickupDate: string): Model => {
  return {
    vin,
    custInfo: {
      firstName: "Client",
      lastName: "Client",
    },
    dealerInfo: {
      dealerAddressLine1: "One Nissan Way",
      dealerCityName: "Franklin",
      dealerCountry: "United States",
      dealerLatitude: "35.939908",
      dealerLongitude: "-86.813911",
      dealerName: "Nissan North America",
      dealerServiceHours: [
        { day: "Monday", range: "7:00am - 7:00pm" },
        { day: "Tuesday", range: "7:00am - 7:00pm" },
        { day: "Wednesday", range: "7:00am - 7:00pm" },
        { day: "Thursday", range: "7:00am - 7:00pm" },
        { day: "Friday", range: "7:00am - 7:00pm" },
        { day: "Saturday", range: "7:00am - 7:00pm" },
        { day: "Sunday", range: "closed" },
      ],
      dealerServicePhone: "(123) 456-7890",
      dealerStateCode: "TN",
      dealerZip: "37067",
    },
    recallInfo: [],
    serviceStatusInfo: {
      serviceAdvisorName: "No Name Provided",
      advisorPhone: "555-555-5555",
      chatUrl: "",
      estimatedPickUp: pickupDate,
      serviceStatus: "CHECK_IN",
    },
    vehicleInfo: {
      exteriorColor: "Black",
      year: "2020",
      model: "Vehicle",
      trim: "",
      make: "Nissan",
    },
  };
};

// This serves as a factory for initializing a model with default values if needed
export const createModelFromAPIPayload = (model: any): Model => {
  // Overwrite all non-default values with the default
  let defaultModel = createDefaultModel("unknown", "No Pickup Time Provided");
  let dealerInfo = Object.assign(
    {},
    defaultModel.dealerInfo,
    model.dealerInfo ? model.dealerInfo : {}
  );
  let vehicleInfo = Object.assign(
    {},
    defaultModel.vehicleInfo,
    model.vehicleInfo ? model.vehicleInfo : {}
  );
  let recallInfo = model.recallInfo
    ? model.recallInfo
    : defaultModel.recallInfo;
  let custInfo = Object.assign(
    {},
    defaultModel.custInfo,
    model.custInfo ? model.custInfo : {}
  );
  let serviceStatusInfo = Object.assign(
    {},
    defaultModel.serviceStatusInfo,
    model.serviceStatusInfo ? model.serviceStatusInfo : {}
  );

  // Fix poorly formatted dealer service hours
  dealerInfo.dealerServiceHours = convertDealerServiceHours(
    dealerInfo.dealerServiceHours
  );

  // Sometimes make is given as an "I" or "N" instead of Nissan or Infiniti. Correct for these convertDealerServiceHours
  switch (vehicleInfo.make) {
    case "I":
      vehicleInfo.make = "Infiniti";
      break;
    case "N":
      vehicleInfo.make = "Nissan";
      break;
  }

  return {
    vin: model.vin,
    dealerInfo,
    vehicleInfo,
    recallInfo,
    custInfo,
    serviceStatusInfo,
  };
};
