import React, { ReactElement } from "react";
import { SidebarButton } from "./SidebarButton";
import phoneIcon from "../resources/phoneIcon.svg";
import avatarPlaceholderIcon from "../resources/advisor-avatar.svg";
import "../styles/ServiceAdvisorView.scss";

const ServiceAdvisorView = (props: {
  name: string;
  number: string;
  chatLink: string;
  profileImageUrl: string;
}): ReactElement => {
  const onCallClicked: () => boolean = () => {
    const analytics = (window as any).firebase_analytics;
    if (!analytics) {
      return true;
    }

    analytics.logEvent("sst_call_advisor_clicked");
    return true;
  };

  return (
    <div className="ServiceAdvisorView">
      <div className="advisorHeader">YOUR SERVICE ADVISOR:</div>
      <div className="advisorContainer">
        <img
          className="advisorAvatar"
          src={avatarPlaceholderIcon}
          alt="Advisor Avatar"
        />
        <div className="flexColumn">
          <div className="advisorName">{props.name}</div>
          <div className="advisorNumber">{props.number}</div>
        </div>
      </div>
      <div className="contactButtonContainer">
        <SidebarButton
          className="callButton"
          title="CALL ADVISOR"
          iconSrc={phoneIcon}
          href={`tel:${props.number}`}
          onClick={onCallClicked}
        />
      </div>
    </div>
  );
};

export default ServiceAdvisorView;
