import React, { useCallback, useEffect, useState } from "react";
import useLayoutInfo from "./util/layoutInfoHook";
import useInterval from "./util/intervalHook";
import HeaderView from "./components/HeaderView";
import MainContentView from "./components/MainContentView";
import SidebarView from "./components/SidebarView";
import { ThemeContext } from "./theme";
import axios, { AxiosRequestConfig } from "axios";

import "./styles/App.scss";
import Model, { createModelFromAPIPayload } from "./models/Model";
import OauthProps from "./models/OauthProps";
import RecallView from "./components/RecallView";
import config from "./config";

import * as dotenv from "dotenv";
import FooterView from "./components/FooterView";

dotenv.config();
// Get the vin from the url
const carGuid = window.location.href.match(/id=(.+)/)?.[1];

const App = () => {
  const { usingMobileLayout } = useLayoutInfo();
  const [model, setModel] = useState<Model | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [refreshTick, setRefreshTick] = useState(0);
  const [oauthProps, setOauthProps] = useState<OauthProps>({
    token: "",
    expiration: 0,
  });

  const fetchOauthToken = useCallback(async (): Promise<OauthProps> => {
    if (oauthProps && Date.now() < oauthProps.expiration) {
      return Promise.resolve(oauthProps);
    }

    const options: AxiosRequestConfig = {
      url: config.tokenUrl,
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + config.wso2AuthToken,
      },
      data: new URLSearchParams({ grant_type: "client_credentials" }),
    };
    const { data } = await axios(options);
    const props: OauthProps = {
      token: data.access_token,
      expiration: Date.now() + data.expires_in * 1000,
    };
    setOauthProps(props);
    return Promise.resolve(props);
  }, [oauthProps]);

  useEffect(() => {
    if (!carGuid) {
      return;
    }

    const fetchModel = (carGuid: string) => {
      const url = `${config.baseUrl}/${carGuid}/data`;
      fetchOauthToken()
        .then((props) => {
          const options: AxiosRequestConfig = {
            method: "get",
            url,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + props.token,
            },
          };
          return axios(options);
        })
        .then(({ data }) => {
          setLoading(false);
          setModel(createModelFromAPIPayload(data));
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          setModel(undefined);
        });
    };

    fetchModel(carGuid);
  }, [fetchOauthToken, refreshTick]);

  useEffect(() => {
    const analytics = (window as any).firebase_analytics;
    if (!analytics || !model) {
      return;
    }

    analytics.logEvent("sst_page_view", {
      dealerName: model.dealerInfo.dealerName,
      estimatedPickUpTime: model.serviceStatusInfo.estimatedPickUp,
      guid: carGuid || "no guid",
      serviceId: carGuid || 'no service id',
      make: model.vehicleInfo.make,
      status: model.serviceStatusInfo.serviceStatus,
    });
  }, [model]);

  useInterval(() => {
    // Increment the refresh tick count to trigger a reload of our data
    setRefreshTick(refreshTick + 1);
  }, 15 * 1000);

  if (loading || !model) {
    return <LoadingContainerView />;
  }

  return (
    <ThemeContext.Provider
      value={{
        mobileLayoutActive: usingMobileLayout,
        make: model.vehicleInfo.make,
        carGuid: carGuid || "",
      }}
    >
      <div className={`App brand-${model.vehicleInfo.make.toLowerCase()}`}>
        <HeaderView />
        {model.recallInfo.length > 0 && (
          <RecallView recalls={model.recallInfo} />
        )}
        <div className="body">
          <div className="content">
            <MainContentView
              bearerToken={oauthProps.token}
              customerName={model.custInfo.firstName}
              dealerName={model.dealerInfo.dealerName}
              vehicle={model.vehicleInfo}
              pickupTime={model.serviceStatusInfo.estimatedPickUp}
              status={model.serviceStatusInfo.serviceStatus}
            />
          </div>
          <div className="sidebar">
            <SidebarView
              serviceAdvisor={{
                name: model.serviceStatusInfo.serviceAdvisorName,
                number: model.serviceStatusInfo.advisorPhone,
                chatLink: "",
              }}
              dealer={{
                name: model.dealerInfo.dealerName,
                address: model.dealerInfo.dealerAddressLine1,
                hours: model.dealerInfo.dealerServiceHours,
              }}
            />
          </div>
        </div>
        <FooterView />
      </div>
    </ThemeContext.Provider>
  );
};

export default App;

const LoadingContainerView = () => {
  return (
    <div className="loading">
      <div>Loading...</div>
    </div>
  );
};
