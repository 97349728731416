import React, { ReactElement, useState } from "react";
import ChevronImage, { Transform } from "./ChevronImage";
import "../styles/DealerHoursView.scss";
import { DealerHoursForDay } from "../models/Model";

const DealerHoursView = (props: {
  hours: DealerHoursForDay[];
  mobileLayoutActive: boolean;
}): ReactElement => {
  const [collapsed, setCollapsed] = useState(false);

  const headerText = `SERVICE DEPARTMENT HOURS${
    props.mobileLayoutActive ? "" : ":"
  }`;
  return (
    <div className="DealerHoursView">
      <div className="headerContainer" onClick={() => setCollapsed(!collapsed)}>
        <div className="text">{headerText}</div>
        {props.mobileLayoutActive && (
          <ChevronImage transform={collapsed ? Transform.Up : Transform.Down} />
        )}
      </div>
      {(collapsed || !props.mobileLayoutActive) &&
        props.hours.map((hours) => (
          <div className="entry" key={hours.day}>
            <div style={{ width: "50%" }}>{hours.day}</div>
            <div style={{ width: "50%" }}>{hours.range}</div>
          </div>
        ))}
    </div>
  );
};

export default DealerHoursView;
