export default {
  baseUrl: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "",
  tokenUrl: process.env.REACT_APP_TOKEN_URL
    ? process.env.REACT_APP_TOKEN_URL
    : "",
  wso2AuthToken: process.env.REACT_APP_WSO2_AUTH_TOKEN
    ? process.env.REACT_APP_WSO2_AUTH_TOKEN
    : "",
  feedbackUrl: process.env.REACT_APP_FEEDBACK_URL
    ? process.env.REACT_APP_FEEDBACK_URL
    : "",
};
