import React, { ReactElement, useContext } from "react";
import "../styles/HeaderView.scss";
import nissanLogo from "../resources/nissan-logo.png";
import infinitiLogo from "../resources/infiniti-logo.png";
import { ThemeContext } from "../theme";

const HeaderView = (): ReactElement => {
  const context = useContext(ThemeContext);
  const logo = context.make === "Infiniti" ? infinitiLogo : nissanLogo;

  return (
    <div className="HeaderView">
      <div className="top"></div>
      <div className="bottom">
        <img className="logo" src={logo} alt="Logo"></img>
        <div className="title">YOUR SERVICE STATUS TRACKER</div>
      </div>
    </div>
  );
};

export default HeaderView;
