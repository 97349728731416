import React, { ReactElement } from "react";
import locationIcon from "../resources/locationIcon.svg";
import { SidebarButton } from "./SidebarButton";
import "../styles/DealerView.scss";

const DealerView = (props: { name: string; address: string }): ReactElement => {
  const queryString = `${props.address}+${props.name}`;
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${queryString}`;

  const onGetDirectionsClicked: () => boolean = () => {
    const analytics = (window as any).firebase_analytics;
    if (!analytics) {
      return true;
    }

    analytics.logEvent("sst_get_directions_clicked");
    return true;
  };

  return (
    <div className="DealerView">
      <div className="name">{props.name.toUpperCase()}</div>
      <div className="address">{props.address}</div>
      <div className="directionsButtonContainer">
        <SidebarButton
          title="GET DIRECTIONS"
          iconSrc={locationIcon}
          href={mapUrl}
          onClick={onGetDirectionsClicked}
        />
      </div>
    </div>
  );
};

export default DealerView;
